<template>
  <main>
    <div id="root">
      <div class="app">
        <div class="header" id="header">
          <div class="header-row">
            <div class="sidebar">
              <NuxtLink :to="localePath('/')" class="logotype">
                <VLogo />
              </NuxtLink>
            </div>
            <div class="content" :class="{ noauth: !$auth.loggedIn }">
              <div class="menu">
                <NuxtLink :to="localePath('/')" exact-active-class="active">{{ $t("header.home") }}</NuxtLink>
                <NuxtLink :to="localePath('/faq')" exact-active-class="active">FAQ</NuxtLink>
                <template v-if="$auth.loggedIn">
                  <NuxtLink :to="localePath('/refs')" exact-active-class="active">{{ $t("header.referral") }}</NuxtLink>
                  <NuxtLink :to="localePath('/bonus')" exact-active-class="active">
                    {{ $t("header.bonuses") }}
                  </NuxtLink>
                  <NuxtLink :to="localePath('/support')" exact-active-class="active">
                    {{ $t("header.support") }}
                  </NuxtLink>

                  <NuxtLink v-if="bank > 0" :to="localePath('/tournaments')" class="link-yellow" exact-active-class="active"
                    >{{
                      $t("header.tournament", {
                        amount: numberWithSpaces(bank),
                      })
                    }}
                    <img src="/images/coin.png" alt="" />
                  </NuxtLink>
                </template>
              </div>
              <div class="right">
                <VLang style="margin-right: 1rem" />
                <VTheme style="margin-right: 1rem" />
                <div v-if="$auth.loggedIn" class="social">
                  <a target="_blank" :href="`https://vk.com/public${$config.public.VK_GROUP}`">
                    <img src="/icons/header/vk.svg" alt="" />
                  </a>
                  <a target="_blank" :href="`https://t.me/${$config.public.TG_GROUP}`">
                    <img src="/icons/tgGradient.png" alt="Telegram" />
                  </a>
                </div>
                <div v-if="!$auth.loggedIn" class="auth" @click.prevent="$vfm.show('auth')">
                  <span class="icon">
                    <img src="/icons/header/auth.svg" alt="" />
                  </span>
                  {{ $t("header.login") }}
                </div>
                <div v-else class="profile">
                  <a v-if="$auth.loggedIn && $auth.user && $auth.user.name" href="#" class="username" @click.prevent="$vfm.show('profile')">{{
                    $auth.loggedIn && $auth.user.name && $auth.user.name.toString().slice(0, 2)
                  }}</a>
                  <img src="/icons/header/wallet.svg" class="icon" alt="" />
                  <span v-if="$auth.loggedIn" class="money">
                    <span v-if="isBalanceHidden">***.**</span>
                    <VOdometer v-else class="" :value="parseFloat(($auth.user && $auth.user.money) || 0)" />
                  </span>
                  <button type="button" @click="banned ? () => ({}) : $vfm.show('payment', { tab: 'withdraw' })" class="withdraw" :disabled="banned">
                    <span class="text">{{ $t("header.withdraw") }}</span>
                    <span class="symbol">-</span>
                  </button>
                  <button type="button" @click="banned ? () => ({}) : $vfm.show('payment', { tab: 'deposit' })" class="payment" :disabled="banned">
                    <span class="text">{{ $t("header.deposit") }}</span>
                    <span class="symbol">+</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-mobile">
          <NuxtLink :to="localePath('/')" class="header-mobile__logo">
            <VLogo mobile />
          </NuxtLink>
          <div v-if="$auth.loggedIn" class="header-mobile__center">
            <img src="/icons/header/wallet.svg" class="header-mobile__center-icon" alt="" />
            <span v-if="$auth.loggedIn" class="header-mobile__center-money">
              <span v-if="isBalanceHidden">***.**</span>
              <VOdometer v-else class="" :value="parseFloat(($auth.user && $auth.user.money) || 0)" />
            </span>

            <a href="#" @click.prevent="banned ? () => ({}) : $vfm.show('payment', { tab: 'deposit' })" class="header-mobile__center-payment" :class="{ disabled: banned }">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.21704 4.78295H10.0329C10.7051 4.78295 11.25 5.32784 11.25 6C11.25 6.67216 10.7051 7.21704 10.0329 7.21704H7.21704V10.033C7.21704 10.7051 6.67215 11.25 6 11.25C5.32784 11.25 4.78295 10.7051 4.78295 10.033V7.21704H1.96704C1.29489 7.21704 0.75 6.67216 0.75 6C0.75 5.32784 1.29489 4.78295 1.96704 4.78295H4.78295V1.96705C4.78295 1.29489 5.32784 0.75 6 0.75C6.67215 0.75 7.21704 1.29489 7.21704 1.96705V4.78295Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="#" @click.prevent="banned ? () => ({}) : $vfm.show('payment', { tab: 'withdraw' })" class="header-mobile__center-withdraw" :class="{ disabled: banned }">
              <svg width="11" height="3" viewBox="0 0 11 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.61 1.748C10.61 2.43725 10.0512 2.996 9.362 2.996H1.498C0.80875 2.996 0.25 2.43725 0.25 1.748V1.748C0.25 1.05875 0.808749 0.5 1.498 0.5H9.36199C10.0512 0.5 10.61 1.05875 10.61 1.748V1.748Z"
                  fill="#CCC9E1"
                />
              </svg>
            </a>
          </div>
          <a v-if="$auth.loggedIn && $auth.user && $auth.user.name" href="#" class="header-mobile__center-username" @click.prevent="$vfm.show('profile')">{{
            $auth.loggedIn && $auth.user.name && $auth.user.name.toString().slice(0, 2)
          }}</a>
          <button type="button" v-else class="header-mobile__login" @click.prevent="$vfm.show('auth')">
            {{ $t("header.login") }}
          </button>
        </div>
        <div class="row">
          <div class="sidebar">
            <div class="sidebar_left">
              <div class="title">
                <img src="/icons/sidebar/games.svg" alt="" />
                {{ $t("header.games_alt") }}
              </div>
              <div class="flex">
                <NuxtLink href="/mainx" exact-active-class="active" class="">
                  <span class="icon">
                    <img src="/images/games/gift-minex.svg" style="width: 20px" alt="mainx" />
                  </span>
                  Money Train
                </NuxtLink>

                <NuxtLink href="/casino/live" class="" exact-active-class="active">
                  <span class="icon icon-flex">
                    <img src="/icons/sidebar/live.svg" alt="live" />
                  </span>
                  LiveGames
                </NuxtLink>
                <NuxtLink href="/casino/slots" class="" exact-active-class="active">
                  <span class="icon icon-flex">
                    <img src="/icons/sidebar/slots.svg" alt="slots" />
                  </span>
                  Slots
                </NuxtLink>
                <NuxtLink href="/dice/bonus" exact-active-class="active" class="">
                  <span class="icon">
                    <img src="/icons/sidebar/dice.svg" alt="Dice" />
                  </span>
                  Bonus Dice
                </NuxtLink>
                <NuxtLink href="/dice" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/icons/sidebar/dice.svg" alt="Dice" />
                  </span>
                  Classic Dice
                </NuxtLink>
                <NuxtLink href="/megawheel" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/icons/sidebar/megawheel.svg" alt="MegaWheel" style="width: 28px; margin-top: 9px" />
                  </span>
                  MegaWheel
                </NuxtLink>
                <NuxtLink href="/bubbles" class="" exact-active-class="active">
                  <span class="icon icon-flex">
                    <img src="/icons/sidebar/stax.svg" alt="bubbles" />
                  </span>
                  Bubbles
                </NuxtLink>
                <NuxtLink href="/x100" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/icons/sidebar/x100.svg" alt="x100" style="width: 28px; margin-top: 9px" />
                  </span>
                  x100
                </NuxtLink>
                <NuxtLink href="/mines" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/icons/sidebar/mines.svg" alt="Mines" />
                  </span>
                  Mines
                </NuxtLink>
                <NuxtLink href="/popit" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/images/vectorPopit.svg?v=4" style="margin-top: 10px" alt="popit" />
                  </span>
                  Popit
                </NuxtLink>
                <NuxtLink href="/hotline" class="" exact-active-class="active">
                  <span class="icon">
                    <img src="/icons/sidebar/hotline.svg" alt="Hotline" />
                  </span>
                  Hotline
                </NuxtLink>
              </div>
            </div>
          </div>
          <div :key="routeKey" class="content">
            <slot></slot>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="main">
          <NuxtLink :to="localePath('/')" class="logotype" style="margin-top: -15px">
            <VLogo />
          </NuxtLink>
          <span class="domain"> Trix - {{ new Date().getFullYear() }} </span>
          <div class="menu">
            <NuxtLink :to="localePath('/terms')" class="">
              {{ $t("footer.terms") }}
            </NuxtLink>
            <NuxtLink :to="localePath('/policy')" class="">
              {{ $t("footer.policy") }}
            </NuxtLink>
            <NuxtLink :to="localePath('/responsible-gambling')" class="">
              {{ $t("footer.responsibility") }}
            </NuxtLink>
            <NuxtLink :to="localePath('/aml-policy')" class=""> AML policy </NuxtLink>
            <NuxtLink :to="localePath('/support')"> {{ $t("footer.support") }} </NuxtLink>
          </div>

          <div style="width: 100%; color: #bbb6d6; font-size: 0.95rem; margin-top: 1rem">
            This website is owned and operated by Novaspin N.V., a company registered and established under the laws of Curaçao, with registration number 163071, with registered address
            Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao.
          </div>
        </div>
      </div>
    </div>
    <LazyAppMobileMenu :show-menu="showMenu" @show-menu="showMenu = !showMenu" />
    <LazyAppMobileNavigation @show-menu="showMenu = !showMenu" />
  </main>
</template>

<script>
import { $vfm } from "vue-final-modal";
import { useGameTournamentsStore } from "@/store/gameTournaments";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { numberWithSpaces } from "../utils";
export default {
  setup() {
    const localePath = useLocalePath();

    const ddX = ref(0);
    const ddY = ref(0);
    const showMenu = ref(false);

    const store = useGameTournamentsStore();
    const { bank } = storeToRefs(store);

    const storeIndex = useStore();
    const { dark, is_collected, deposit, snow } = storeToRefs(storeIndex);

    const route = useRoute();

    const isBalanceHidden = computed(() => {
      return route.name === "casino-game-id";
    });

    watch(
      () => showMenu.value,
      (val) => {
        if (val) {
          document.body.classList.add("overflow-hidden");
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      }
    );

    const onHoverBonus = (e) => {
      const btn = document.querySelector("#bonusdd");
      if (btn) {
        const rect = btn.getBoundingClientRect();
        /*const header = document
          .querySelector("#header")
          .getBoundingClientRect();*/
        ddX.value = rect.x - rect.width / 2; //- header.x;
        ddY.value = rect.y + rect.height; //- header.x;
      }
    };
    watch(
      () => route.name,
      () => {
        showMenu.value = false;
      }
    );

    const htmlClass = computed(() => {
      const darkz = dark.value ? "theme__dark" : "def";
      const snowz = false ? "snower" : "def";
      return [darkz, snowz].join(" ");
    });
    const { t } = useI18n();
    useHead({
      title: t("header.title"),
      bodyAttrs: {
        class: "h-100",
        lang: "ru",
      },
      htmlAttrs: {
        class: htmlClass,
      },
    });
    const modals = ref({});

    const { $auth } = useNuxtApp();
    const banned = computed(() => {
      return $auth.user?.bans?.find((el) => el.type === "self:exclusion");
    });
    return {
      modals,
      onHoverBonus,
      ddX,
      ddY,
      showMenu,
      bank,
      numberWithSpaces,
      is_collected,
      deposit,
      isBalanceHidden,
      localePath,
      banned,
    };
  },
  computed: {
    routeKey() {
      return `page-${this.$route.path}`;
    },
  },
};
</script>

<style lang="scss">
#root .app .header .header-row .content .menu .btn {
  background: transparent;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b6b0d7;
  font-weight: 500;
  margin-right: 40px;
  line-height: 70px;
  font-size: 14px;
  display: inline-block;
  position: relative;
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.header-row .content .gifts {
  &::after {
    background: linear-gradient(90deg, #76ac41 3.33%, #97d25b 100%) !important;
  }
}

.header {
  position: relative;
}

.header-dd {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b6b0d7;
  font-weight: 500;
  margin-right: 40px;
  line-height: 70px;
  font-size: 14px;
  display: inline-block;
  // position: relative;
  background: transparent;

  &__dd {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 15px 85px rgb(215 211 236 / 45%);
    z-index: 100;

    & > * {
      width: 100%;
    }
  }

  &:active,
  &:hover {
    .header-dd__dd {
      display: flex;
    }
  }
}

.icon-flex {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.overflow-hidden {
  @media screen and (max-width: 760px) {
    overflow: hidden;
  }
}

.link-yellow {
  &:hover {
    color: #f3be00 !important;
  }

  display: flex !important;
  align-items: center;
  // text-shadow: 1px 2px 21px rgba(243, 190, 0, 1);

  img {
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
}
</style>
