<template>
  <button class="btn-theme" type="button" @click.prevent="toggle(!dark)" :class="{ 'btn-theme--active': dark }">
    <svg v-if="!dark" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.0051 0.287784C8.30646 1.49566 7.9066 2.89798 7.9066 4.3937C7.9066 8.90531 11.5446 12.5672 16.0471 12.6058C14.6272 15.0607 11.9731 16.7124 8.93316 16.7124C4.39754 16.7124 0.720703 13.0355 0.720703 8.49993C0.720703 3.96432 4.39754 0.287476 8.93316 0.287476C8.95716 0.287476 8.98115 0.287578 9.0051 0.287784Z"
        fill="#B6B0D7"
      />
    </svg>
    <svg v-else width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7671 17.9039C11.8872 17.9039 10.3633 16.3799 10.3633 14.5C10.3633 12.6201 11.8872 11.0962 13.7671 11.0962C15.647 11.0962 17.171 12.6201 17.171 14.5C17.171 16.3799 15.647 17.9039 13.7671 17.9039Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M22.2766 12.7981H23.9785C24.9185 12.7981 25.6804 13.5601 25.6804 14.5C25.6804 15.44 24.9185 16.2019 23.9785 16.2019H22.2766C21.3366 16.2019 20.5747 15.44 20.5747 14.5C20.5747 13.5601 21.3366 12.7981 22.2766 12.7981ZM18.3743 7.54632L19.5777 6.34287C20.2423 5.67823 21.3199 5.67823 21.9846 6.34287C22.6492 7.00752 22.6492 8.08511 21.9846 8.74976L20.7811 9.9532C20.1165 10.6178 19.0389 10.6178 18.3743 9.9532C17.7096 9.28856 17.7096 8.21096 18.3743 7.54632ZM18.3743 21.5142C17.7096 20.8495 17.7096 19.7719 18.3743 19.1073C19.0389 18.4427 20.1165 18.4427 20.7811 19.1073L21.9846 20.3107C22.6492 20.9754 22.6492 22.053 21.9846 22.7176C21.3199 23.3823 20.2423 23.3823 19.5777 22.7176L18.3743 21.5142ZM3.55544 12.7981H5.25736C6.19731 12.7981 6.95928 13.5601 6.95928 14.5C6.95928 15.44 6.19731 16.2019 5.25736 16.2019H3.55544C2.61549 16.2019 1.85352 15.44 1.85352 14.5C1.85352 13.5601 2.61549 12.7981 3.55544 12.7981ZM13.767 2.58655C14.7069 2.58655 15.4689 3.34852 15.4689 4.28847V5.99039C15.4689 6.93034 14.7069 7.69232 13.767 7.69232C12.827 7.69232 12.0651 6.93034 12.0651 5.99039V4.28847C12.0651 3.34852 12.827 2.58655 13.767 2.58655ZM13.767 21.3077C14.7069 21.3077 15.4689 22.0697 15.4689 23.0096V24.7115C15.4689 25.6515 14.7069 26.4135 13.767 26.4135C12.827 26.4135 12.0651 25.6515 12.0651 24.7115V23.0096C12.0651 22.0697 12.827 21.3077 13.767 21.3077ZM5.60984 6.34287C6.27448 5.67823 7.35208 5.67823 8.01672 6.34287L9.22017 7.54632C9.88481 8.21096 9.88481 9.28856 9.22017 9.9532C8.55552 10.6178 7.47793 10.6178 6.81328 9.9532L5.60984 8.74976C4.9452 8.08511 4.9452 7.00752 5.60984 6.34287ZM5.60984 22.7176C4.9452 22.053 4.9452 20.9754 5.60984 20.3107L6.81328 19.1073C7.47793 18.4427 8.55552 18.4427 9.22017 19.1073C9.88481 19.7719 9.88481 20.8495 9.22017 21.5142L8.01672 22.7176C7.35208 23.3823 6.27448 23.3823 5.60984 22.7176Z"
        fill="white"
      />
    </svg>
  </button>
</template>

<script setup>
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
const store = useStore();
const { dark } = storeToRefs(store);
const toggle = (val) => {
  dark.value = val;
  localStorage.setItem("dark", val);
};
</script>

<style lang="scss">
.btn-theme {
  width: 43px;
  height: 43px;
  background: #e7e4fb;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    //width: 27px;
    //height: 27px;
  }

  &--active {
    background: radial-gradient(50% 50% at 50% 50%, #a2bdff 0%, #6b38ff 100%);
  }
}

.settingsModal {
  .btn-theme {
    margin-left: auto !important;
    margin-top: auto !important;
    width: 48px;
    height: 48px;
  }
}
</style>
