<template>
  <button class="btn-lang" type="button" @click.prevent="toggle()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      class=""
      v-if="locale === 'en'"
    >
      <g>
        <circle cx="256" cy="256" r="256" fill="#f0f0f0" data-original="#f0f0f0"></circle>
        <path
          fill="#0052b4"
          d="M496.077 345.043C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043H15.923C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304z"
          data-original="#0052b4"
          class=""
        ></path>
        <path fill="#d80027" d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512z" data-original="#d80027" class=""></path>
      </g>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      class=""
      v-else
    >
      <g>
        <circle cx="256" cy="256" r="256" fill="#f0f0f0" data-original="#f0f0f0" class=""></circle>
        <g fill="#0052b4">
          <path
            d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076z"
            fill="#0052b4"
            data-original="#0052b4"
            class=""
          ></path>
        </g>
        <g fill="#d80027">
          <path
            d="M509.833 222.609H289.392V2.167A258.556 258.556 0 0 0 256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 0 0 0 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0 0 66.783 0V289.392h220.442A258.533 258.533 0 0 0 512 256c0-11.317-.744-22.461-2.167-33.391z"
            fill="#d80027"
            data-original="#d80027"
          ></path>
          <path
            d="M322.783 322.784 437.019 437.02a256.636 256.636 0 0 0 15.048-16.435l-97.802-97.802h-31.482zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0 0 16.435 15.048l97.802-97.804zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 0 0-15.048 16.435l97.803 97.803h31.481zM322.783 189.219 437.02 74.981a256.328 256.328 0 0 0-16.435-15.047l-97.802 97.803z"
            fill="#d80027"
            data-original="#d80027"
          ></path>
        </g>
      </g>
    </svg>
  </button>
</template>

<script setup>
const i18n = useI18n();
const locale = i18n.locale;

const active = ref(false);

async function toggle() {
  if (active.value) return;
  active.value = true;
  const target = locale.value === "ru" ? "en" : "ru";

  await i18n.setLocale(target);
  active.value = false;
}
</script>

<style lang="scss">
.btn-lang {
  width: 43px;
  height: 43px;
  background: #e7e4fb;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 22px;
    height: 22px;
  }

  &.transparent {
    background: transparent;
  }
}
</style>
